<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Thêm cấu hình hành động segment</h6>
        </template>
        <div class="content">
          <b-row>
            <b-col class="col-md-6">
              <b-form-group label="Tiêu đề (*)">
                <b-form-input v-model="params.title"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="col-md-6">
              <b-form-group label="Mô tả ngắn">
                <b-textarea v-model="params.description" rows="5"></b-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="col-md-6">
              <b-form-group label="Đối tượng (*)">
                <b-form-radio-group
                    v-model="params.object"
                    :options="object"
                    name="object-radio-group"
                    stacked
                >
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="col-md-6">
              <b-form-group v-if="params.object==='PHONE'">
                <b-form-input v-model="params.segments.phone"
                              placeholder="0966666666;0966666667;0966666668"></b-form-input>
                <div class="mt-4">
                  <b-button
                      type="button"
                      variant="secondary"
                      class="mr-2 mt-4"
                      @click="showUploadModal"
                  >
                    <i class="fas fa-save"></i> Upload File
                  </b-button>
                  <span v-if="file ">
          {{ file.name }}
          <button @click="removeFile" class="text-danger" type="button">
            <i class="fa fa-trash text-danger ml-2" style="margin-top: 20px;" aria-hidden="true"></i>
          </button>
        </span>
                </div>
              </b-form-group>

              <b-form-group v-if="params.object==='GROUP'">
                <b-form-input v-model="params.segments.group" placeholder="1;2;3;4;5"></b-form-input>
              </b-form-group>

              <b-form-group v-if="params.object==='SEGMENTS'">
                <multiselect v-model="params.segments.segments" :options="segments" :multiple="true" :taggable="true"
                             label="text" track-by="text"></multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="col-md-6">
              <b-form-group>
                <b-form-select :options="actions" v-model="params.action"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="col-md-6">
              <b-form-group
                            label="Lý do *">
                <b-form-input type="text" v-model="reason"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <div class="text-center">
                <b-button type="button" variant="primary" @click="handleClick()" class="mr-2"><i
                    class="fas fa-save"></i> Lưu
                </b-button>
                <b-button type="button" variant="danger" @click="cancel()"><i class="fas fa-times"></i> Hủy</b-button>
              </div>
            </b-col>
          </b-row>
          <b-modal id="upload-modal" ref="uploadModal" title="Upload Excel File" @hide="resetFile">
            <b-form>
              <button type="button" class="btn btn-primary  " @click="downloadFile()">
                Download file mẫu
              </button>
              <b-form-group label="Import file có định dạng .xlsx, .xls">
                <b-form-file
                    v-model="tempFile"
                    accept=".xlsx, .xls"
                    drop-placeholder="Thả file vào đây..."
                ></b-form-file>
              </b-form-group>
            </b-form>
            <template #modal-footer="{ ok, cancel }">
              <b-button variant="secondary" @click="cancelUpload()">Hủy</b-button>
              <b-button variant="primary" @click="confirmUpload(ok)">OK</b-button>
            </template>
          </b-modal>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import Notifications from "vue-notification";
import Common from "../../../../core/mixins/common";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Vue from "vue";
import Multiselect from "vue-multiselect";

Vue.component("multiselect", Multiselect)
Vue.use(Notifications);

const CmsRepository = RepositoryFactory.get("cms");

export default {
  mixins: [Common],
  components: {Multiselect},
  data() {
    return {
      object: [
        {text: "Segment", value: "SEGMENTS"},
        // { text: "Group", value: "GROUP" },
        {text: "SĐT", value: "PHONE"},
      ],
      actions: [
        {text: "Khóa tài khoản", value: "LOCK_ACCOUNT"},
        {text: "Mở khóa tài khoản", value: "UNLOCK_ACCOUNT"},
        {text: "Khóa số dư tài khoản", value: "ACCOUNT_WALLET_LOCK"},
        {text: "Mở khóa số dư tài khoản", value: "ACCOUNT_WALLET_UNLOCK"},
      ],
      segments: [],
      services: [],
      file: null,
      fileUploaded: false,
      tempFile: null,
      reason: null,
      params: {
        title: null,
        description: null,
        segments: {
          group: null,
          segments: null,
          phone: null
        },
        action: "LOCK_ACCOUNT",
        object: [{text: "Segment", value: "SEGMENTS"},
          {text: "SĐT", value: "PHONE"},]
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Thêm cấu hình hành động segment", route: "create"},
    ]);
  },
  methods: {

    async handleClick() {

      try {

        if (this.params.object === "PHONE") {
          if (this.params.segments.phone === null && this.file === null) {
            this.notifyAlert("warn", "Vui lòng nhập số điện thoại hoặc tải lên file.");
            return;
          }
          if (this.file && (this.params.action === 'ACCOUNT_WALLET_LOCK' || this.params.action === 'ACCOUNT_WALLET_UNLOCK')) {
            await this.importFile();
          } else {
            await this.createConfig();
          }
        }
        if (this.params.object === "SEGMENTS") {
          if (this.params.segments.segments === null) {
            this.notifyAlert("warn", "Vui lòng chọn segment.");
            return;
          } else {
            await this.createConfig();
          }
        }

      } catch (error) {
        this.notifyAlert("error", "Đã xảy ra lỗi trong quá trình thực hiện.");
      }
    },
    async createConfig() {
      this.$bus.$emit("show-loading", true);
      let params = this.convert(this.params);
      let response = await CmsRepository.createSegmentAction(params);
      this.$bus.$emit("show-loading", false);
      let body = response.data;

      if (body.error_code > 0) {
        let data = body.data;

        if (typeof data.title !== "undefined") {
          this.notifyAlert("warn", data.title[0]);
        } else if (typeof data.segments !== "undefined") {
          this.notifyAlert("warn", data.segments[0]);
        } else {
          this.notifyAlert("warn", body.message);
        }

        return true;
      }

      this.notifyAlert("success", body.message);
      window.location = "/#/tools/segment/action";
    },
    async getSegment() {
      this.segments = [];
      this.$bus.$emit("show-loading", true);
      let params = {per_page: 1000};
      let response = await CmsRepository.listSegmentAvailable(params);
      this.$bus.$emit("show-loading", false);
      let body = response.data;

      if (body.error_code > 0) {
        this.notifyAlert("warn", body.message);
        return true;
      }

      let data = body.data.data;
      let segments = [];
      data.forEach(function (item) {
        segments.push({text: item.title, value: item.id});
      });

      this.segments = segments;

      // this.notifyAlert("success", body.message);
    },
    async importFile() {

      if (this.file === null) {
        this.notifyAlert("warn", "File không được bỏ trống");
        return;
      }
      if (this.reason === null) {
        this.notifyAlert("warn", "Lý do không được bỏ trống");
        return;
      }
      if (this.file.size <= 0) {
        this.notifyAlert("warn", "File rỗng, vui lòng kiểm tra lại");
        return;
      }
      if (this.params.title === null) {
        this.notifyAlert("warn", "Tiêu đề không được để trống");
        return;
      }

      let formData = this.createFormData();
      this.$bus.$emit("show-loading", true);
      try {
        let response = await CmsRepository.importFilePhone(formData);
        if (response.data.error_code) {
          this.notifyAlert("warn", response.data().message);
          return;
        } else {
          this.notifyAlert("success", "Đẩy file thành công");
          window.location = "/#/tools/segment/action";
        }
      } catch (error) {
        this.notifyAlert("warn", "Có lỗi xảy ra");
      }

    },
    cancel() {
      window.location = "/#/tools/segment/action";
    },
    convert(param) {
      return {
        title: param.title,
        description: param.description,
        segments: {
          group: param.segments.group,
          segments: param.segments.segments,
          phone: param.segments.phone,
        },
        action: param.action,
        object: param.object,
        reason: this.reason
      };
    },
    createFormData() {
      let param = new FormData();

      param.append("file", this.file);
      param.append("reason", this.reason);
      param.append("type", this.params.action);

      param.append('title', this.params.title);
      param.append('description', this.params.description);
      param.append('action', this.params.action);
      param.append('object', this.params.object);

      if (this.params.segments) {
        param.append('segments[group]', this.params.segments.group);
        param.append('segments[segments]', this.params.segments.segments);
        param.append('segments[phone]', this.params.segments.phone);
      }
      return param;
    },
    showUploadModal() {
      this.$refs.uploadModal.show();
    },

    removeFile() {
      this.file = null;
    },
    resetFile() {
      if (!this.fileUploaded) {
        this.tempFile = null;
      }
    },
    confirmUpload(ok) {
      if (this.tempFile) {
        this.fileUploaded = true
        this.file = this.tempFile
        ok();
      } else {
        this.notifyAlert("warn", "Vui lòng chọn một file trước khi tải lên.");
      }

    },
    cancelUpload() {
      this.tempFile = null;
      this.fileUploaded = false;
      this.$refs.uploadModal.hide();
    },

    downloadFile() {
      const fileUrl = process.env.BASE_URL + 'media/fileExcel/import_users.xlsx';
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = 'import_users.xlsx';
      link.click();
    }
  },
  created() {
    this.getSegment();
  },
  watch: {},
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
